import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';
import Container from "react-bootstrap/Container";
import ToggleLayout from "../components/toggle-layout";
import danger from '../images/alert-triangle.svg';
import SettingAlert from "../components/setting-alert";
import DatePicker from "react-datepicker";
import FeatherIcon from "feather-icons-react";
import PlotReport from "./plot-report";
import {dateFormat, isEmpty} from "../shared/utils/utils";
import {toggleLoader} from "../shared/actions/setting";
import {useDispatch} from "react-redux";
import {GRANULARITY} from "../utils/Enum";
import {isSuper} from "../utils/Authentication";
import CustomerVisitHeatMap from './customer-visit-heat-map';

export default function Home(props){
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDataRange] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  let title = '';
  const [selectedDropdownPlotId, setSelectedDropdownPlotId] = useState(null);
  const [visibleToggleIndex, setVisibleToggleIndex] = useState(0);


  useEffect(() => {
    console.log("visibleToggleIndex", visibleToggleIndex);
  }, [visibleToggleIndex]);

  if (isToday(selectedDate)) {
    title = "Today's total arrival";
  } else if (isYesterday(selectedDate)) {
    title = "Yesterday's total arrival";
  } else {
    title = formatDate(selectedDate) + "'s total arrival";
  }

  function isToday(date) {
    const today = new Date();
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  }

  function isYesterday(date) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear();
  }

  function formatDate(date) {
    const options = {year: 'numeric', month: 'long', day: 'numeric'};
    return date.toLocaleDateString('en-US', options);
  }

  const datePickerRef = useRef(null);
  const [summary, setSummary] = useState({});
  const [kitId, setKitId] = useState(props.location?.state?.farm?.value || "nolimit-Image");
  const [selectedLabel, setSelectedLabel] = useState("Wellawatte Image");
  const [filters, setFilters] = useState({aggregation: "SUM", granularity: GRANULARITY.HOURS});
  const [deviceIds, setDeviceIds] = useState([]);  
  const [kitData, setKitData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    axios.get(process.env.REACT_APP_HOST + `/core/kit/` + kitId)
      .then(function (response) {
        setKitData(response.data.content);
        setDeviceIds(response.data.content.devices);
        setIsLoading(false);
      })
      .catch(function (error) {
      })
      .finally(() => {
      });

    getHistoryByNumber(0, true);
  }, [selectedDate, kitId]);

  const handleIconClick = () => {
    datePickerRef.current.setFocus();
  };


  function getHistoryByNumber(number, isSilence) {
    let fromDate = dateFormat(selectedDate);
    let toDate = dateFormat(selectedDate);

    if (!isSilence) {
      setIsLoading(true);
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/core/kit/` + kitId + '/graph-kit-history/' + number + '/summary?from=' + fromDate + '&to=' + toDate +
      (isEmpty(filters) ? '' : '&aggregation=' + (filters.aggregation ? filters.aggregation : "MAX") + '&granularity=' + (filters.granularity ? filters.granularity : "HOURS") + (filters.aggregation === "TIME" ? '&timeOfDay=9' : "")))
      .then(function (response) {
        if (!isEmpty(response.data.content)) {

          let data = response.data.content;
          let i = data.valueY.indexOf(Math.max(...data.valueY));
          let summary = {};

          if (i === 23) {
            summary.peakTime = data.valueX[i].substring(11) + " - " + "00:00";
          } else {
            summary.peakTime = data.valueX[i].substring(11) + " - " + data.valueX[i + 1].substring(11);
          }

          summary.peakCount = data.valueY[i];

          let sum = data.valueY.reduce((partialSum, a) => partialSum + a, 0);
          let currentHour = new Date().getHours()
          let todaySum = data.valueY.slice(8, currentHour + 1).reduce((partialSum, a) => partialSum + a, 0);
          if (isToday(selectedDate)) {
            summary.average = currentHour > 21 ? Math.ceil(todaySum / 14) : Math.ceil(todaySum / (currentHour - 7));
          } else {
            summary.average = Math.ceil(sum / 14);
          }
          summary.total = sum;
          setSummary(summary);

        }
        if (!isSilence) {
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
        if (!isSilence) {
          setIsLoading(false);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }

  return (

    <div className='container'>
      {/* <select className={"location-filter align-items-center mb-3"} onChange={(e) => {
        const selectedOption = e.target.options[e.target.selectedIndex];
        setKitId(e.target.value);
        setSelectedLabel(selectedOption.textContent);
      }}value={kitId}>
        <option value={"nolimit-Image"}>{"Wellawatte Image"}</option>
        <option value={"nolimit_wellawatte-royal-A"}>{"Wellawatte Royal"}</option>
        <option value={"nolimit_ccc-A"}>{"CCC"}</option>
        <option value={"nolimit_dehiwala-ladies-A"}>{"Dehiwala Ladies"}</option>
        <option value={"nolimit_dehiwala-gents-A"}>{"Dehiwala Gents"}</option>
        <option value={"nolimit_havelock-city-A"}>{"Havelock City"}</option>
        <option value={"nolimit_maharagama_A"}>{"Maharagama"}</option>
        <option value={"nolimit_ogf-A"}>{"OGF Kids"}</option>
        <option value={"nolimit_ratmalana-A"}>{"Ratmalana"}</option>
        <option value={"nolimit_rathnapura-A"}>{"Rathnapura"}</option>
        <option value={"nolimit_kandy-A"}>{"Kandy"}</option>
        <option value={"nolimit_kandy_kcc-A"}>{"Kandy KCC"}</option>
        <option value={"nolimit_kurunegala-A"}>{"Kurunegala"}</option>
        <option value={"nolimit_panadura-A"}>{"Panadura"}</option>
        <option value={"nolimit_negombo-424-A"}>{"Negombo 424"}</option>
        <option value={"nolimit_negombo-456-A"}>{"Negombo Nolimit"}</option>
        <option value={"nolimit_wattala-A"}>{"Wattala"}</option>
      </select> */}
      <div hidden={isLoading} className="single-card m-rl-m-8 plot-report-container">
        <div>
          <div className={'row justify-content-between'} style={{padding: "8px 8px"}}>
            <div className={"header-text"}>Project Statistics</div>
            <div className={'row justify-content-between widget-container'}>
              <div className={"view-filter sa-table-btn-mute border-0 date-picker-container"}>
                <FeatherIcon className="calendar-icon" icon="calendar" onClick={handleIconClick}/>
                <DatePicker className={"date-picker-text-box border-0"}
                            onChange={date => setSelectedDate(date)}
                            selected={selectedDate}
                            name={"date"}
                            dateFormat={"dd/MM/yyyy"}
                            showYearDropdown
                            maxDate={new Date()}
                            ref={datePickerRef}
                />
              </div>
            </div>
          </div>
          <div className={'row justify-content-between'}>
            <div className={'col-lg col-md-6'}>
              <div className={"dashboard-widget-card"}>
                <div className={"dashboard-yield-card"}>
                  <div className={"dashboard-widget-card-layout"}>
                    <div className={'dashboard-widget-card-icon-container'}>
                      <FeatherIcon icon={"corner-right-up"} color={"var(--red)"}/>
                    </div>
                    <div className={"dashboard-widget-card-text-layout"}>
                      <div className='widget-value font-size-18'>
                        {summary.peakTime}
                      </div>
                      <div className={"widget-text-value"}>Peak Time
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={'col-lg col-md-6'}>
              <div className={"dashboard-widget-card"}>
                <div className={"dashboard-yield-card"}>
                  <div className={"dashboard-widget-card-layout"}>
                    <div className={'dashboard-widget-card-icon-container'}>
                      <FeatherIcon icon={"users"} color={"var(--primary)"}/>
                    </div>
                    <div className={"dashboard-widget-card-text-layout"}>
                      <div className='widget-value font-size-18'>
                        {summary.peakCount}
                      </div>
                      <div className={"widget-text-value"}>Arrival on peak time
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={'col-lg col-md-6'}>
              <div className={"dashboard-widget-card"}>
                <div className={"dashboard-yield-card"}>
                  <div className={"dashboard-widget-card-layout"}>
                    <div className={'dashboard-widget-card-icon-container'}>
                      <FeatherIcon icon={"activity"} color={"var(--orange)"}/>
                    </div>
                    <div className={"dashboard-widget-card-text-layout"}>
                      <div className='widget-value font-size-18'>
                        {summary.average}
                      </div>
                      <div className={"widget-text-value"}>Average arrival/hour
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={'col-lg col-md-6'}>
              <div className={"dashboard-widget-card"}>
                <div className={"dashboard-yield-card"}>
                  <div className={"dashboard-widget-card-layout"}>
                    <div className={'dashboard-widget-card-icon-container'}>
                      <FeatherIcon icon={"hash"} color={"var(--green)"}/>
                    </div>
                    <div className={"dashboard-widget-card-text-layout"}>
                      <div className='widget-value font-size-18'>
                        {summary.total}
                      </div>
                      <div className={"widget-text-value"}>
                        {title}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div hidden={isLoading} className="single-card m-rl-m-8 plot-report-container">
        <PlotReport
          kitId={kitId}
          selectedLabel={selectedLabel}
          statisticsDate={selectedDate}
        />
      </div>
      <div hidden={isLoading} className="single-card m-rl-m-8 plot-report-container">
        <CustomerVisitHeatMap deviceIds={deviceIds}/>
      </div>
      {isSuper() &&
        <Container style={{padding: "0px", marginBottom: 10}}>
          <ToggleLayout image={danger} title={"Alert Settings"} dropDown={true}
                        onPlotChange={(plotId) => setSelectedDropdownPlotId(plotId)} value={selectedDropdownPlotId}
                        visibleToggleIndex={visibleToggleIndex} toggleIndex={1}
                        onVisibleToggleIndexChange={(index) => setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)}>
            {visibleToggleIndex === 1 &&
              <SettingAlert kitId={kitId}/>
            }
          </ToggleLayout>
        </Container>
      }
    </div>
  );
}

