import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';
import FeatherIcon from "feather-icons-react";
import {dateFormat, isEmpty} from "../shared/utils/utils";
import {toggleLoader} from "../shared/actions/setting";
import {useDispatch} from "react-redux";
import {GRANULARITY} from "../utils/Enum";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";




const BranchData = (props) => {

  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  let title = '';
  const [visibleToggleIndex, setVisibleToggleIndex] = useState(0);

  const history = useHistory();

  const navigateToHome = () => {
    history.push({pathname:"/home", state: {farm: props.farm}});
    
  };


  useEffect(() => {
    console.log("visibleToggleIndex", visibleToggleIndex);
  }, [visibleToggleIndex]);

 

  function isToday(date) {
     const today = new Date();
     return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
   }

  

  
  const [summary, setSummary] = useState({});
  const [kitId, setKitId] = useState(props.farm.value);
  const [filters, setFilters] = useState({aggregation: "SUM", granularity: GRANULARITY.HOURS});
  const [kitData, setKitData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    axios.get(process.env.REACT_APP_HOST + `/core/kit/` + kitId)
      .then(function (response) {
        console.log(response);
        setKitData(response.data.content);
        setIsLoading(false);
      })
      .catch(function (error) {
      })
      .finally(() => {
      });

    getHistoryByNumber(0, true);
  }, [selectedDate, kitId]);

 
  const getTimeOnly = (datetime) => {
    return datetime.split(' ')[1];
  };

  const convertToAmPm = (time) => {
    const [hours, minutes] = time.split(':');
    const amPm = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12;
    return `${adjustedHours}:${minutes} ${amPm}`;
  };

  function getHistoryByNumber(number, isSilence) {
    let fromDate = dateFormat(selectedDate);
    let toDate = dateFormat(selectedDate);

    if (!isSilence) {
      setIsLoading(true);
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/core/kit/` + kitId + '/graph-kit-history/' + number + '/summary?from=' + fromDate + '&to=' + toDate +
      (isEmpty(filters) ? '' : '&aggregation=' + (filters.aggregation ? filters.aggregation : "MAX") + '&granularity=' + (filters.granularity ? filters.granularity : "HOURS") + (filters.aggregation === "TIME" ? '&timeOfDay=9' : "")))
      .then(function (response) {
   
    // console.log('API Response Data:', response.data);
        if (!isEmpty(response.data.content)) {

          let data = response.data.content;
          let i = data.valueY.indexOf(Math.max(...data.valueY));
          let summary = {};

          if (i === 23) {
            summary.peakTime = data.valueX[i].substring(11) + " - " + "00:00";
          } else {
            summary.peakTime = data.valueX[i].substring(11) + " - " + data.valueX[i + 1].substring(11);
          }

          summary.peakCount = data.valueY[i];

          let sum = data.valueY.reduce((partialSum, a) => partialSum + a, 0);
          let currentHour = new Date().getHours()
          let todaySum = data.valueY.slice(8, currentHour + 1).reduce((partialSum, a) => partialSum + a, 0);
          if (isToday(selectedDate)) {
            summary.average = currentHour > 21 ? Math.ceil(todaySum / 14) : Math.ceil(todaySum / (currentHour - 7));
          } else {
            summary.average = Math.ceil(sum / 14);
          }
          summary.total = sum;
          summary.lastUpdated = new Date().toLocaleTimeString();
          setSummary(summary);

        }
        if (!isSilence) {
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
        if (!isSilence) {
          setIsLoading(false);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }

  return (
    <div 
    key={props.key} className="single-card ma-res-bot sa-cursor" id={'farm'}  style={{ padding: 0 }} onClick={navigateToHome}>

      <div className={"single-card-header card-top-border"} id={'card-header'}>
        <div className="card-header-text limit-charater-farmname" id={'farmText'} data-toggle="tooltip">
          <label title={""} className="heading"><FeatherIcon className="map-icon" icon="map-pin"/> {props.farm.name}</label>
          {props.farm.meta && <div
            className={'meta-name mb-2'}>{props.farm.meta.D_CANAL + "-" + props.farm.meta.FC_CANAL + "-" + props.farm.meta.IRRIGATION_NUMBER}</div>}
        </div>
      </div>
      <div className="single-card-content">
        <div className="row">
          <div className="col-6 text-center padding-f">
          {/* <div className={'col-lg col-md-6'}> */}
              <div className={"dashboard-widget-card-2nd"}>
                <div className={"dashboard-yield-card"}>
                  <div className={"dashboard-widget-card-layout"}>
                    <div className={'dashboard-widget-card-icon-container'}style={{ width: "50px", height: "40px" }}>
                      <FeatherIcon icon={"bar-chart-2"} color={"var(--green)"}/>
                    </div>
                    <div className={"dashboard-widget-card-text-layout-2nd"}>
                      <div className='widget-value-2nd font-size-18' style={{ lineHeight: "25px" }}>
                        {summary.total}
                      </div>
                      <div className={"widget-text-value-2nd"}>Today's Total Arrival
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/* </div> */}
          </div>
          <div className="col-6 text-center padding-t">
          {/* <div className={'col-lg col-md-6'}> */}
              <div className={"dashboard-widget-card-2nd"}>
                <div className={"dashboard-yield-card"}>
                  <div className={"dashboard-widget-card-layout"}>
                    <div className={'dashboard-widget-card-icon-container'}style={{ width: "50px", height: "40px" }}>
                      <FeatherIcon icon={"activity"} color={"var(--yellow)"}/>
                    </div>
                    <div className={"dashboard-widget-card-text-layout-2nd"}>
                      <div className='widget-value-2nd font-size-18' style={{ lineHeight: "25px" }}>
                        {summary.average}
                      </div>
                      <div className={"widget-text-value-2nd"}>Average Arrival/hour
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/* </div> */}
          </div>
          <div className="col-6 text-center padding-s">
          {/* <div className={'col-lg col-md-6'}> */}
              <div className={"dashboard-widget-card-2nd"}>
                <div className={"dashboard-yield-card"}>
                  <div className={"dashboard-widget-card-layout"}>
                    <div className={'dashboard-widget-card-icon-container'}style={{ width: "50px", height: "40px" }}>
                      <FeatherIcon icon={"corner-down-right"} color={"var(--blue)"}/>
                    </div>
                    <div className={"dashboard-widget-card-text-layout-2nd"}>
                      <div className='widget-value-2nd font-size-18'style={{ lineHeight: "25px" }}>
                      {kitData.lastSeen ? convertToAmPm(getTimeOnly(kitData.lastSeen)) : 'N/A'}
                      </div>
                      <div className={"widget-text-value-2nd"}>Last Data Arrival
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/* </div> */}
          </div>
          <div className="col-6 text-center padding-t">
          {/* <div className={'col-lg col-md-6'}> */}
              <div className={"dashboard-widget-card-2nd"}>
                <div className={"dashboard-yield-card"}>
                  <div className={"dashboard-widget-card-layout"}>
                    <div className={'dashboard-widget-card-icon-container'}style={{ width: "50px", height: "40px" }}>
                      <FeatherIcon icon={"trending-up"} color={"var(--red)"}/>
                    </div>
                    <div className={"dashboard-widget-card-text-layout-2nd"}>
                      <div className='widget-value-2nd font-size-18'style={{ lineHeight: "25px" }}>
                        {summary.peakTime}
                      </div>
                      <div className={"widget-text-value-2nd"}>Today's Peak Time
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BranchData
