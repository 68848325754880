import React, {useEffect, useState} from 'react';
import FeatherIcon from "feather-icons-react";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {TOGGLE} from "../utils/Enum";
import {changeToggle} from "../shared/actions/setting";


function ToggleButton(props) {

  const [hideNavigation, setHideNavigation] = useState(false);
  const toggle = useSelector(state => {
    return state.setting.toggle
  });
  let history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();


  useEffect(() => {
    let paths = ["/","/dashboard"]
    if (paths.includes(location.pathname)) {
      setHideNavigation(true);
    } else {
      setHideNavigation(false);
    }
  }, [location]);

  const selectedService = useSelector(state => {
    return state.modules.selectedService;
  });

  console.log(location.pathname)

  return (
    <div>
      <div className="container container-toggle-btn mb-5" hidden={hideNavigation}>
        <div className={"sa-bread-crumb"}>
          <div className={"d-flex"}>
            <div className={"sa-bc-home sa-cursor"} onClick={() => {
              history.push("/")
            }}><FeatherIcon className={"icon-in-btn"} icon="home"/></div>

            <div
              className={"sa-bc-farm " + (location.pathname !== "/services" ? "sa-cursor" : "") + (false ? "sa-bc-right-border" : "")}
              onClick={() => {
                if (location.pathname === "/services") {
                  return
                }
                history.push("/services")
              }}
              hidden={false}>
              <FeatherIcon className={"sa-bc-arrow"} icon="chevron-right"/>
              <FeatherIcon className={"icon-in-btn"} icon="layers"/>
              <span className={"hide-768"}> {location.state?.farm.name}</span>
            </div>
            <div className={"sa-bc-plot"} onClick={() => {
            }} hidden={location.pathname.length < 10}>
              <FeatherIcon className={"sa-bc-arrow"} icon="chevron-right"/>
              <FeatherIcon className={"icon-in-btn"} icon="layout"/>
              {selectedService.name}
            </div>

            {/*              {(!(location.pathname).includes("xindicate") &&searchEnable()) &&<span className="sa-table-btn-mute sa-search-align sa-participant-search border-0 h-36">*/}
            {/*                      <input className={'sa-table-search sa-table-search-point font-14'}*/}
            {/*                             name={t("placeholder.SEARCH")}*/}
            {/*                             value={searchValue || ''}*/}
            {/*                             onChange={handleSearch}*/}
            {/*                             autoComplete={"off"} type="text" placeholder="Search"/>*/}
            {/*                      <FeatherIcon className={'sa-search-icon'}*/}
            {/*                                   icon={"search"} width={'16px'}/>*/}
            {/*</span>}*/}
          </div>
        </div>


        <div className="pull-right toggle-btn" hidden={location.pathname !=="/services"}>
          <button id="toggle-list"
                  className={"list btn first-tog first-tog-size " + (toggle===TOGGLE.LIST ? 'p-l-11 height-44 tog-sa-primary hide-768 ' : 'tog-sa-secondary ')}
                  onClick={() => {
                    dispatch(changeToggle(TOGGLE.LIST))
                  }}>
            <FeatherIcon className={"icon-in-btn"} icon="grid"/> <span className={"hide-768"}> LIST</span>
          </button>

          <button id="toggle-map"
                  className={"map btn second-tog second-tog-map " + (toggle===TOGGLE.CARD ? 'tog-sa-primary hide-768' : 'tog-sa-secondary')}
                  onClick={() => {

                    dispatch(changeToggle(TOGGLE.CARD))
                  }}>
            <FeatherIcon className={"icon-in-btn"} icon="map"/> <span className={"hide-768"}> CARD</span>
          </button>

          {/*<Tour*/}
          {/*    steps={steps}*/}
          {/*    isOpen={isTourOpen}*/}
          {/*    onRequestClose={() => setIsTourOpen(false)}*/}
          {/*    accentColor={"#22c687"}*/}
          {/*    rounded={5}*/}
          {/*/>*/}
        </div>
      </div>
    </div>
  );
}

export default ToggleButton;
