import React, {useEffect, useState} from "react";
import axios from "axios";
import { use } from "i18next";
import { dispatch, timeout } from "d3";
import {toggleLoader} from "../shared/actions/setting";
import { useDispatch } from "react-redux";

const CustomerVisitHeatMap = (props) => {
    const [images, setImages] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [warnings, setWarnings] = useState([]);

    const deviceIds = props.deviceIds;

    const dispatch = useDispatch();

    useEffect(() => {
        getImages();
    }, []);

    function getImages(){
        const apiUrl = `${process.env.REACT_APP_IMAGE_API_HOST}/heat`;

        const inputData = {
            device_id: "he",    // mac address
            start: "2024-10-15_09:28",
            end: "2024-10-15_11:00",
            stream_ids: [1, 2, 3],              // array of stream ids
            heat_height_ratio_from_bottom: 1, // between 0 and 1
            alpha_value: 0.5,                   // between 0 and 5
            dummy_remove: true,                 // boolean
        };
        setIsLoading(true);
        dispatch(toggleLoader(true));
        axios.post(apiUrl, inputData, { timeout: 300000  })
            .then(function (response) {
                const { images, warning_msgs } = response.data;
                setImages(images);
                setWarnings(warning_msgs);
            })
            .catch((error) => {
                console.error("Error fetching heatmap images:", error);
            })
            .finally(() => {
                setIsLoading(false);
                dispatch(toggleLoader(false));
            });
    }

    const renderImages = () => {
        return Object.keys(images).map((streamId) => (
            <div key={streamId} style={{ marginBottom: "20px" }}>
                <h6>Heatmap of {streamId}</h6>
                <div style={{ marginBottom: "10px" }}>
                    <img
                        src={`data:image/jpeg;base64,${images[streamId][0]}`}  //get the heat map image for the stream ids
                        alt={`Heatmap for Stream ${streamId}`}
                        style={{ width: "100%", height: "auto" }}
                    />
                </div>
            </div>
        ));
    };
    

    const Indicator = ({ color, label }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{
            width: "15px", 
            height: "15px", 
            backgroundColor: color, 
            borderRadius: "3px", 
            margin: "0px 8px"
          }}></div>
          <div>{label}</div>
        </div>
    );

    return (
        <div>
            <div className="row justify-content-between" style={{ padding: "8px 16px" }}>
                <div className="header-text">Customer Visit Heat Map</div>
                <div className="row justify-content-between widget-container">
                    <Indicator color="#00E100" label="Low" />
                    <Indicator color="#FEC802" label="Medium" />
                    <Indicator color="#FF0000" label="High" />
                </div>
                </div>
            <div className={'row justify-content-between'} style={{padding: "8px 16px"}}>
                <div className="sa-popup-content form-group w-25" style={{margin: 0}}>
                    <select
                        type="text"
                        className={`form-control`}
                        name="section"
                        placeholder="Enter Level"
                    >
                        <option value="ladies_section">ladies Section</option>
                        <option value="gents_section">Gents Section</option>
                        <option value="child_section">Child Section</option>
                    </select>
                </div>
                <div className={'row justify-content-end widget-container'}>
                    <div className={"header-text"}>2nd Floor</div>
                </div>
            </div>
            <div style={{ padding: "15px" }}>
                {isLoading ? (
                    <p>Loading Images, Please Wait.</p>
                ) : (
                    Object.keys(images).length > 0 ? renderImages() : <p>No images available.</p>
                )}
            </div>
        </div>
    );
};

export default CustomerVisitHeatMap;
