import FeatherIcon from "feather-icons-react";
import React from "react";

export function goToHomePage(history){
    history.push("/home");
}

export function allAreEqual(array) {
    const result = array.every(element => {
        if (element === array[0]) {
            return true;
        }
    });

    return result;
}

export function icons() {
    return (<div className={"service-card-icon mr-3"}>
        <FeatherIcon
            className={"txt-green"}
            icon="user"
        />
    </div>)
}


