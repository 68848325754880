import React, { useEffect, useState } from "react";
import BranchData from "./branch-data";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FeatherIcon from "feather-icons-react";

const Dashboard = () => {
  const [farms, setFarms] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); 


  const branchData = [
    { value: "nolimit-Image", name: "Wellawatte Image" },
    { value: "nolimit_wellawatte-royal-A", name: "Wellawatte Royal" },
    { value: "nolimit_ccc-A", name: "CCC" },
    { value: "nolimit_dehiwala-ladies-A", name: "Dehiwala Ladies" },
    { value: "nolimit_dehiwala-gents-A", name: "Dehiwala Gents" },
    { value: "nolimit_havelock-city-A", name: "Havelock City" },
    { value: "nolimit_maharagama_A", name: "Maharagama" },
    { value: "nolimit_ogf-A", name: "OGF Kids" },
    { value: "nolimit_ratmalana-A", name: "Ratmalana" },
    { value: "nolimit_rathnapura-A", name: "Rathnapura" },
    { value: "nolimit_kandy-A", name: "Kandy" },
    { value: "nolimit_kandy_kcc-A", name: "Kandy KCC" },
    { value: "nolimit_kurunegala-A", name: "Kurunegala" },
    { value: "nolimit_panadura-A", name: "Panadura" },
    { value: "nolimit_negombo-424-A", name: "Negombo 424" },
    { value: "nolimit_negombo-456-A", name: "Negombo Nolimit" },
    { value: "nolimit_wattala-A", name: "Wattala" },
  ];

  useEffect(() => {
    setFarms(branchData);
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredFarms = farms.filter((farm) =>
    farm.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="smart-farm">
      <div className="container mb-3">
        <span className="sa-table-btn-mute sa-search-align sa-participant-search border-0 h-36">
          <input
            className={"sa-table-search sa-table-search-point font-14"}
            name={"search"}
            value={searchQuery} 
            onChange={handleSearchChange} 
            autoComplete={"off"}
            type="text"
            placeholder="Search"
          />
          <FeatherIcon className={"sa-search-icon"} icon={"search"} width={"16px"} />
        </span>
      </div>
      {window.innerWidth > 200 ? (
        <div id="listView" className={"list-view full-list-view half-list-view"}>
          <div className={"empty-scroll-list bg-img-none"} />
          <div className={"half-list-container half-list-container map-space"}>
            <Container className={"m-t-8"}>
              <Row id={"m-rl-m-16-2"} >
                {filteredFarms.map((item, index) => (
                  <Col xl={4} lg={6} key={"col" + index} className={"m-b-4"}>
                    <BranchData farm={item} key={index} />
                  </Col>
                ))}
                {filteredFarms.length === 0 && (
                  <div className={"empty-results"}>
                    <FeatherIcon icon="info" />
                    <div className={"empty-results-text"}>There are no farms available, please add</div>
                  </div>
                )}
              </Row>
            </Container>
          </div>
        </div>
      ) : (
        <div id="listView" className={"list-view full-list-view ma-res-lis mo-list-view"} />
      )}
    </div>
  );
};

export default Dashboard;
