import React, {Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Loader from "../shared/components/loader";
import Navbar from "../components/navbar";
import ConfirmationDialog from "../shared/components/confirmation-dialog";
import ToggleButton from "../components/toggleButton";
import Home from "../components/home";
import Dashboard from "../components/dashboard";


const MainLayout = () => {


    return (
        <div className={"h-100pe bg-gray"}>
            <Suspense fallback={<Loader load={true}/>}>
                <Navbar/>
                <ConfirmationDialog/>

            </Suspense>
            <div className={"list-view"}>
                <ToggleButton/>

                <Suspense fallback={<Loader load={true}/>}>
                <Switch>
                    <Route path="/dashboard" component={Dashboard}/>
                    <Redirect exact from='/' to='/dashboard'/>
                    <Route path="/home" component={Home}/>
                    
                  
                </Switch>
            </Suspense>
            </div>
        </div>

    );
};

export default MainLayout;
